<template>
    <footer>
        <ul>
            <li>
                <a href="https://instagram.com/insidemateria" target="_blank">
                    <svg width="25" height="25" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.11412 0C5.9509 0 5.6815 0.00881539 4.83081 0.0465956C1.93768 0.17253 0.331856 1.69507 0.199144 4.44044C0.160657 5.24768 0.151367 5.50458 0.151367 7.55605C0.151367 9.60878 0.160657 9.86568 0.199144 10.6717C0.331856 13.4158 1.93768 14.9408 4.83081 15.0668C5.6815 15.1033 5.9509 15.1121 8.11412 15.1121C10.2773 15.1121 10.5481 15.1033 11.3987 15.0668C14.2866 14.9408 15.899 13.4183 16.0291 10.6717C16.0676 9.86568 16.0769 9.60878 16.0769 7.55605C16.0769 5.50458 16.0676 5.24768 16.0291 4.44044C15.899 1.69885 14.2919 0.17127 11.3987 0.0465956C10.5481 0.00881539 10.2773 0 8.11412 0ZM8.11309 1.3623C10.2391 1.3623 10.4913 1.36986 11.3314 1.40638C13.4906 1.49957 14.4966 2.47053 14.5961 4.5031C14.6333 5.30027 14.6412 5.53828 14.6412 7.55575C14.6412 9.57321 14.6333 9.81249 14.5961 10.6084C14.4966 12.6397 13.4919 13.6132 11.3314 13.7064C10.4913 13.7416 10.2405 13.7504 8.11309 13.7504C5.98703 13.7504 5.73488 13.7429 4.89614 13.7064C2.73292 13.6119 1.73094 12.6372 1.63141 10.6084C1.59425 9.81249 1.58496 9.57321 1.58496 7.55575C1.58496 5.53828 1.59425 5.29901 1.63141 4.5031C1.72962 2.46927 2.73558 1.49831 4.89614 1.40512C5.73488 1.3686 5.98703 1.3623 8.11309 1.3623ZM4.02515 7.55581C4.02515 5.41241 5.85658 3.67578 8.11402 3.67578C10.3715 3.67578 12.2029 5.41367 12.2029 7.55581C12.2029 9.69921 10.3715 11.4358 8.11402 11.4358C5.85658 11.4358 4.02515 9.69921 4.02515 7.55581ZM8.11397 10.0745C6.64749 10.0745 5.45972 8.94736 5.45972 7.55579C5.45972 6.16548 6.64749 5.03711 8.11397 5.03711C9.57911 5.03711 10.7695 6.16422 10.7695 7.55579C10.7695 8.94736 9.57911 10.0745 8.11397 10.0745ZM11.4075 3.52391C11.4075 3.0227 11.8361 2.61719 12.363 2.61719C12.8925 2.61719 13.3199 3.0227 13.3199 3.52391C13.3199 4.02513 12.8912 4.43064 12.363 4.43064C11.8348 4.43064 11.4075 4.02387 11.4075 3.52391Z" fill="white"/>
                    </svg>
                </a>
            </li>
            <li>
                © Copyright 2022. Artifact
            </li>
            <li>
                <a href="https://materia.art/privacy-policy/" target="_blank">
                    Privacy Policy
                </a>
            </li>
            <li>
                <a href="https://materia.art/contact-us/" target="_blank">
                    Contact us
                </a>
            </li>
        </ul>
    </footer>
</template>