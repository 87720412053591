import { createRouter,createWebHistory  } from 'vue-router'
const routes = [
    {
        path : '/',
        component : ()=>import('@/components/Home.vue')
    },
    {
        path: '/drop/:id',
        component: () => import('@/components/DropInside.vue')
    }
]

const router = createRouter({
    history : createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        window.scrollTo(0,0);
      }
})

export default router;