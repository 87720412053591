import { createApp } from "vue";
import App from "./App.vue";
import "./assets/scss/_style.scss";
import store from "./store/store";
import router from "./routes";
import firebase from 'firebase'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


const firebaseConfig = {
    apiKey: "AIzaSyAaz987rMofBgJAj3Xa8k5ynaKGflX0BZE",
    authDomain: "materia-dfe55.firebaseapp.com",
    projectId: "materia-dfe55",
    storageBucket: "materia-dfe55.appspot.com",
    messagingSenderId: "858666757847",
    appId: "1:858666757847:web:97079881d75cfbd970ca92"
  };
  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

const app = createApp(App)
app.use(router)
app.use(store)
app.use(Toast)
app.mount("#app");

