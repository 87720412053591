<template>
    <header>
        <router-link to="/">
            <figure>
             <img src="~@/assets/logo.png" alt="materia logo">
            </figure>
        </router-link>
        <nav>
            <ul>
                <li><a href="#">About</a></li>
                <li>
                    <img src="~@/assets/icons/search.svg" alt="">
                </li>
            </ul>
            <figure>
                <img src="@/assets/icons/menu.svg" alt="">
            </figure>
        </nav>
    </header>
</template>

<script>
export default {
    
}
</script>